<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceAnalysis-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
       <div class="common-input-container">
        <span>基准值:</span>
        <el-select
          class="common-screen-input"
          v-model="params.referenceType"
          @change="commonFun"
          placeholder="请选择"
          filterable
        >
          <el-option key="other" label="客户" value="other"> </el-option>
          <el-option key="system" label="系统" value="system"> </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>账号:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.keyword"
        ></el-input>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            serviceKPIList();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button
      >
      <br />
      <!-- <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          :clearable="false"
          @change="
            () => {
              changeDate();
            }
          "
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <el-radio-group v-model="params.type" @change="changeRadio">
          <el-radio-button :label="1">近7天</el-radio-button>
          <el-radio-button :label="2">近30天</el-radio-button>
          <el-radio-button :label="3">近90天</el-radio-button>
          <el-radio-button :label="0">昨天</el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="common-input-container">
        <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-lately-date>
      </div>
    </div>
    <el-table class="common-table" :data="tableData" @sort-change="tableSort">
      <el-table-column
      fixed
        prop="area"
        label="地区（分公司）"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
      fixed
        prop="shopName"
        label="店铺名称"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
      <el-table-column prop="subaccountName" label="员工账号" width="120px" fixed>
        <template slot-scope="scope">
          <span class="nameBtn" @click="() => nameBtn(scope.row.adminId, scope.row.subaccountName)">{{
            scope.row.subaccountName || "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="loginPlatform"
        label="登录平台"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="frequency"
        label="班次"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="loginAccount"
        label="登录账号"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        sortable="custom"
        prop="consultationNum"
        label="总咨询量"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        sortable="custom"
        prop="consultationAvgNum"
        label="日均咨询量"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column label="首响(s)" sortable="custom" prop="firsrTime" width="120px">
        <template slot-scope="scope">
          <span :class="scope.row.firstTimeFlag == '1' && scope.row.firsrTime ? 'warning' : ''">{{
            scope.row.firsrTime || "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平响(s)" sortable="custom" prop="avgTime" width="120px">
        <template slot-scope="scope">
          <span :class="scope.row.avgTimeFlag == '1' && scope.row.avgTime ? 'warning' : ''">{{
            scope.row.avgTime || "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="问答比" sortable="custom" prop="qaRate" width="120px">
        <template slot-scope="scope">
          <span :class="scope.row.qaRateFlag == '0' && scope.row.qaRate ? 'warning' : ''">{{
            scope.row.qaRate
              ? (scope.row.qaRate * 100).toFixed(2) == "NaN"
                ? "--"
                : (scope.row.qaRate * 100).toFixed(2) + "%"
              : "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="邀评率"
        sortable="custom"
        prop="inviteComments"
        width="120px"
      >
        <template slot-scope="scope">
          <span :class="scope.row.inviteCommentsFlag == '1' && scope.row.inviteComments ? 'warning' : ''">{{
            scope.row.inviteComments
              ? (scope.row.inviteComments * 100).toFixed(2) == "NaN"
                ? "--"
                : (scope.row.inviteComments * 100).toFixed(2) + "%"
              : "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        prop="responseRate"
        label="回复率"
        width="120px"
      >
        <template slot-scope="scope">
          <span>{{
              scope.row.responseRate
                ? (scope.row.responseRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.responseRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        prop="conversionRate"
        label="转化率"
        width="120px"
      >
        <template slot-scope="scope">
          <span>{{
              scope.row.conversionRate
                ? (scope.row.conversionRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.conversionRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="满意度" sortable="custom" prop="satisfaction" width="120px">
        <template slot-scope="scope">
          <span :class="scope.row.satisfactionFlag == '0' && scope.row.satisfaction ? 'warning' : ''">{{
            scope.row.satisfaction || "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="satisfactionDifference"
        :formatter="tableColumn"
        label="满意度差值"
        sortable="custom"
        width="120px"
      ></el-table-column>
      <el-table-column
        label="工单错误率"
        sortable="custom"
        prop="orderErrorRate"
        width="120px"
      >
        <template slot-scope="scope">
          <span :class="scope.row.orderErrorRateFlag == '1' && scope.row.orderErrorRate ? 'warning' : ''">{{
            scope.row.orderErrorRate
              ? (scope.row.orderErrorRate * 100).toFixed(2) == "NaN"
                ? "--"
                : (scope.row.orderErrorRate * 100).toFixed(2) + "%"
              : "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderErrorRateDifference"
        :formatter="tableColumn"
        sortable="custom"
        label="工单错误率差值"
        width="160px"
      ></el-table-column>
      <el-table-column
        label="质检错误率"
        sortable="custom"
        prop="qualityErrorRate"
        width="120px"
      >
        <template slot-scope="scope">
          <span :class="scope.row.qualityErrorRateFlag == '1' && scope.row.qualityErrorRate ? 'warning' : ''">{{
            scope.row.qualityErrorRate
              ? (scope.row.qualityErrorRate * 100).toFixed(2) == "NaN"
                ? "--"
                : (scope.row.qualityErrorRate * 100).toFixed(2) + "%"
              : "--"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="qualityErrorRateDifference"
        :formatter="tableColumn"
        label="质检错误率差值"
        sortable="custom"
        width="160px"
      ></el-table-column>
      <el-table-column
        label="服务高压线"
        sortable="custom"
        prop="serviceHighVoltage"
        width="120px"
      >
        <template slot-scope="scope">
          <span :class="scope.row.serviceHighVoltageFlag == '1' && scope.row.serviceHighVoltage ? 'warning' : ''">{{
            scope.row.serviceHighVoltage || "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="遗漏客诉"
        sortable="custom"
        prop="missFeedback"
        width="120px"
      >
        <template slot-scope="scope">
          <span :class="scope.row.missFeedback == '1' && scope.row.missFeedback ? 'warning' : ''">{{
            scope.row.missFeedback || "--"
          }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/data/customer-service-analysis/service-kpi/detail',
                  query: {
                    adminId: scope.row.adminId,
                    subaccountName: scope.row.subaccountName,
                    customerId: $route.query.customerId,
                    shopId: scope.row.shopId,
                    shopName: scope.row.shopName,
                    type: params.type,
                    startDate: DateTransform(params.datePicker[0]),
                    endDate: DateTransform(params.datePicker[1]),
                  },
                });
              }
            "
            >客服KPI详情</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <service-kpi-detail ref="serviceDetail"> </service-kpi-detail>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import ServiceKpiDetail from "./serviceKPIDetail.vue";
import { serviceKPIList } from "../../service/dataInfo.js";
import { tableColumn, DateTransform, getLatelyDay } from "../../utils/index.js";
import CommonLatelyDate from "../../components/common/commonLatelyDate.vue"
export default {
  components: { Breadcrumb, ServiceKpiDetail, CommonLatelyDate },
  data() {
    return {
      loading: false,
      tableColumn,
      DateTransform,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "店铺服务分析", isLink: true, url: "/data/customer-service-analysis" },
        {
          title: "店铺KPI",
          isLink: true,
          url: `/data/customer-service-analysis/shop-kpi?companyId=${this.$route.query.companyId}&&customerId=${this.$route.query.customerId}&customerName=${this.$route.query.customerName}&startDate=${this.$route.query.startDate}&endDate=${this.$route.query.endDate}&commonRadio=${this.$route.query.commonRadio}&referenceType=${this.$route.query.referenceType}&guestFlag=${this.$route.query.guestFlag}`,
        },
        { title: "客服KPI", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      remarkForm: {},
      commonRadio: this.$route.query.commonRadio,
      params: {
        companyId: this.$route.query.companyId,
        shopId: this.$route.query.shopId,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        referenceType: this.$route.query.referenceType,
        guestFlag: this.$route.query.guestFlag,
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      pickerOptions: {},
    };
  },
  created() {
    this.serviceKPIList();
  },
  methods: {
    commonGetDate (startTime, endTime, commonRadio) {
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.commonRadio = commonRadio
      this.serviceKPIList()
    },
    nameBtn(adminId, subaccountName) {
      let params = { ...this.params };
      params.adminId = adminId;
      if (params.pageSize) {
        delete params.pageSize;
      }
      if (params.page) {
        delete params.page;
      }
      this.$refs["serviceDetail"].getParams(params, adminId, subaccountName, this.commonRadio || null);
    },
    tableSort(e) {
      // 表格项排序
      let params = { ...this.params };
      params.sortField = e.prop;
      console.log(e.prop);
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.serviceKPIList();
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.serviceKPIList();
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.serviceKPIList();
    },
    changeBreadcrumb(num) {
      // 面包屑点击事件
      this.nowBreadcrumb = num;
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.serviceKPIList();
    },
    handleSelectionChange(row) {
      console.log(row);
    },
    resetBtn() {
      this.commonRadio = this.$route.query.commonRadio
      this.params = {
        companyId: this.$route.query.companyId,
        shopId: this.$route.query.shopId,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        referenceType: this.$route.query.referenceType,
        guestFlag: this.$route.query.guestFlag,
        page: 1,
        pageSize: 10,
      };
      this.$refs.commonReset.resetFun(this.$route.query.commonRadio, this.$route.query.startDate, this.$route.query.endDate)
      this.serviceKPIList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.serviceKPIList();
    },
    async serviceKPIList() {
      // 子账号列表
      this.loading = true;
      let params = { ...this.params };
      // if (params.datePicker && params.datePicker.length) {
      //   params.startDate = DateTransform(params.datePicker[0]);
      //   params.endDate = DateTransform(params.datePicker[1]);
      // }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await serviceKPIList({ ...params })).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.serviceAnalysis-container {
  text-align: left;
  .nameBtn {
    color: #409eff;
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
  .warning {
    color: #f00;
  }
  .breadcrumbStyle {
    color: #00000073;
    font-weight: 500;
  }
  .breadcrumbCursor {
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
